import { Business, EconomicActivity, Employment, EmploymentSubsidy, TaxData, UserDetails } from '@jupiter/shared';
import { api } from '../api';

export const userDetailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateUserDetails: builder.mutation<UserDetails, UserDetails>({
      query: (body) => ({
        url: 'user-details',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Profile', 'UserDetails', 'Dashboard', 'Taxes', 'IRS', 'SS', 'VAT'],
    }),
    getUserDetails: builder.query<UserDetails, void>({
      query: () => ({
        url: 'user-details',
      }),
      providesTags: ['UserDetails'],
    }),
    updateTaxDataHistory: builder.mutation<TaxData[], TaxData[]>({
      query: (body) => ({
        url: 'user-details/tax-data-history',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Profile', 'UserDetails', 'Dashboard', 'Taxes', 'IRS', 'SS', 'VAT'],
      async onQueryStarted(_response, { dispatch, queryFulfilled }) {
        try {
          const { data: updated } = await queryFulfilled;
          dispatch(
            userDetailsApi.util.updateQueryData('getTaxDataHistory', undefined, (draft) => {
              Object.assign(draft, updated);
            }),
          );
        } catch {
          // No action
        }
      },
    }),
    getTaxDataHistory: builder.query<TaxData[], void>({
      query: () => ({
        url: 'user-details/tax-data-history',
      }),
    }),
    updateEmploymentHistory: builder.mutation<Employment[], Employment[]>({
      query: (body) => ({
        url: 'user-details/employment-history',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Profile', 'UserDetails', 'Dashboard', 'Taxes', 'IRS', 'SS', 'VAT'],
      async onQueryStarted(_response, { dispatch, queryFulfilled }) {
        try {
          const { data: updated } = await queryFulfilled;
          dispatch(
            userDetailsApi.util.updateQueryData('getEmploymentHistory', undefined, (draft) => {
              Object.assign(draft, updated);
            }),
          );
        } catch {
          // No action
        }
      },
    }),
    getEmploymentHistory: builder.query<Employment[], void>({
      query: () => ({
        url: 'user-details/employment-history',
      }),
    }),
    updateEmploymentSubsidies: builder.mutation<EmploymentSubsidy[], EmploymentSubsidy[]>({
      query: (body) => ({
        url: 'user-details/employment-subsidies',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Profile', 'UserDetails', 'Dashboard', 'Taxes', 'IRS', 'SS', 'VAT'],
      async onQueryStarted(_response, { dispatch, queryFulfilled }) {
        try {
          const { data: updated } = await queryFulfilled;
          dispatch(
            userDetailsApi.util.updateQueryData('getEmploymentSubsidies', undefined, (draft) => {
              Object.assign(draft, updated);
            }),
          );
        } catch {
          // No action
        }
      },
    }),
    getEmploymentSubsidies: builder.query<EmploymentSubsidy[], void>({
      query: () => ({
        url: 'user-details/employment-subsidies',
      }),
    }),
    updateBusinessHistory: builder.mutation<Business[], Business[]>({
      query: (body) => ({
        url: 'user-details/business-history',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Profile', 'UserDetails', 'Dashboard', 'Taxes', 'IRS', 'SS', 'VAT'],
      async onQueryStarted(_response, { dispatch, queryFulfilled }) {
        try {
          const { data: updated } = await queryFulfilled;
          dispatch(
            userDetailsApi.util.updateQueryData('getBusinessHistory', undefined, (draft) => {
              Object.assign(draft, updated);
            }),
          );
        } catch {
          // No action
        }
      },
    }),
    getBusinessHistory: builder.query<Business[], void>({
      query: () => ({
        url: 'user-details/business-history',
      }),
    }),
    getEconomicActivities: builder.query<EconomicActivity[], void>({
      query: () => ({
        url: 'user-details/economic-activities',
      }),
    }),
    importEconomicActivities: builder.mutation<void, void>({
      query: () => ({
        url: 'user-details/economic-activities/import',
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useUpdateUserDetailsMutation,
  useUpdateBusinessHistoryMutation,
  useUpdateEmploymentHistoryMutation,
  useUpdateTaxDataHistoryMutation,
  useUpdateEmploymentSubsidiesMutation,
  useGetUserDetailsQuery,
  useGetBusinessHistoryQuery,
  useGetEmploymentHistoryQuery,
  useGetTaxDataHistoryQuery,
  useGetEconomicActivitiesQuery,
  useGetEmploymentSubsidiesQuery,
  useImportEconomicActivitiesMutation,
} = userDetailsApi;
