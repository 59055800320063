export function getFirstAndLastNames(fullName?: string): string[] {
  const split = fullName?.split(' ') ?? [];

  if (!split.length) {
    return ['', ''];
  }

  return [split[0], split.at(1) ?? ''];
}

export function getFirstAndLastInitials(fullName?: string): string[] {
  const names = getFirstAndLastNames(fullName);

  return [names[0].charAt(0).toUpperCase(), names[1].charAt(0).toUpperCase()];
}
