import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';
import { usePostAssistantMessageMutation } from '../../redux/assistant/assistant.api';
import ChatError from './chat-error';
import ChatInput from './chat-input';
import ChatMessage from './chat-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    window: {
      width: '40vw',
      height: '100%',
      maxHeight: '100%',
      backgroundColor: '#ECECEC',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    container: {
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  }),
);

interface Message {
  from: 'user' | 'assistant';
  content: string;
}

export default function ChatWindow() {
  const classes = useStyles();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [postMessage, { isLoading, isError, error, data }] = usePostAssistantMessageMutation();
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setMessages((m) => [...m, { content: data.content, from: 'assistant' }]);
  }, [data]);

  const handleMessageSubmit = async (message: { content: string }) => {
    setMessages((m) => [...m, { content: message.content, from: 'user' }]);

    postMessage({ content: message.content });
  };

  return (
    <Box className={classes.window} display="flex" flexDirection="column">
      <Box flexGrow={1} className={classes.container}>
        <Box padding={2}>
          {messages.map(({ from, content }, index) => (
            <Box marginBottom={3} key={`message-${index}`}>
              <ChatMessage from={from} content={content} />
            </Box>
          ))}
          {isError && <ChatError error={error} />}
          <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef} />
        </Box>
      </Box>
      <Box padding={2}>
        <ChatInput onSubmit={handleMessageSubmit} isLoading={isLoading} />
      </Box>
    </Box>
  );
}
