import { ASSISTANT_THROTTLED, ASSISTANT_UNAVAILABLE } from '@jupiter/shared';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ReactElement } from 'react';
import { isApiError } from '../../common/helpers/error.helpers';

interface ChatErrorProps {
  error: any;
}

export default function ChatError(props: ChatErrorProps): ReactElement | null {
  const { error } = props;

  if (!error) {
    return null;
  }

  let message = 'Ocorreu um erro inesperado. Por favor, tente mais tarde.';
  if (isApiError(error)) {
    const { data } = error;
    const { type } = data;

    if (type === ASSISTANT_THROTTLED) {
      message =
        'Estás a enviar mensagens com muita frequência. Por favor, aguarda um pouco antes de enviares outra mensagem.';
    } else if (type === ASSISTANT_UNAVAILABLE) {
      message = 'O assistente não está disponível de momento. Por favor, tenta mais tarde.';
    }
  }

  return (
    <Alert severity="error">
      <AlertTitle>Oh não! O não te consigo ajudar de momento.</AlertTitle>
      {message}
    </Alert>
  );
}
