import MarkedViewer from '@jnbelo/react-marked';
import { getFirstAndLastInitials } from '@jupiter/shared';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useGetProfileQuery } from '../../redux/authentication/authentication.api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    userMessage: {
      backgroundColor: theme.palette.background.default,
    },
    assistantMessage: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    avatar: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      width: theme.spacing(3),
      height: theme.spacing(3),
      padding: theme.spacing(1),
    },
    avatarInitials: {
      fontSize: '12px',
    },
  }),
);

interface ChatMessageProps {
  from: 'user' | 'assistant';
  content: string;
}

export default function ChatMessage(props: ChatMessageProps) {
  const { from, content } = props;
  const classes = useStyles();

  const { data: profileInfo } = useGetProfileQuery();

  const isUser = from === 'user';

  return (
    <Paper className={clsx([classes.root, { [classes.userMessage]: isUser, [classes.assistantMessage]: !isUser }])}>
      <Box display="flex" flexDirection="row">
        {isUser && (
          <Avatar className={classes.avatar}>
            <Typography className={classes.avatarInitials}>{getFirstAndLastInitials(profileInfo?.name)}</Typography>
          </Avatar>
        )}
        <MarkedViewer content={content} />
      </Box>
    </Paper>
  );
}
