import { ApiError } from '@jupiter/shared';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export function isError(error: unknown): error is Error {
  return (error as Error).message !== undefined;
}

export function isApiError(error: unknown): error is FetchBaseQueryError & { data: ApiError } {
  return (
    (error as FetchBaseQueryError).status !== undefined &&
    (error as FetchBaseQueryError).data !== undefined &&
    ((error as FetchBaseQueryError).data as ApiError).type !== undefined
  );
}
