import { AssistantMessage } from '@jupiter/shared';
import { api } from '../api';

export const assistantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    postAssistantMessage: builder.mutation<AssistantMessage, AssistantMessage>({
      query: (body) => ({
        url: 'assistant/message',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Assistant'],
      extraOptions: { maxRetries: 5 },
    }),
  }),
  overrideExisting: false,
});

export const { usePostAssistantMessageMutation } = assistantApi;
