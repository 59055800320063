import {
  AppBar,
  Box,
  Chip,
  Container,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../components/navigation/private-route';
import useNavigation from '../navigation/nav.hook';
import { privateRoutes } from '../navigation/routes';
import Sidebar from '../navigation/sidebar';
import { AbilityProvider, Can } from '../providers/ability.provider';
import { useGetProfileQuery } from '../redux/authentication/authentication.api';
import useAuthentication from '../redux/authentication/authentication.hooks';
import ChatDrawer from './components/chat-drawer';
import PaymentAlert from './components/payment.alert';

const DRAWER_WIDTH = 281;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    borderBottom: '1px solid #45567080',
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
  demoAccountButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  subscribeButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
  },
  pioneerButton: {
    color: '#FF672F',
    borderColor: '#FF672F',
  },
}));

function WarningChip(props: {
  type: string;
  isInTrial: boolean;
  onSubscribeClick: () => void;
  onTrialClick: () => void;
}) {
  const classes = useStyles();
  const { type, isInTrial, onSubscribeClick, onTrialClick } = props;

  if (type === 'demo') {
    return (
      <>
        <Box sx={{ mx: 1 }}>
          <Chip
            label={'Subscrever'}
            clickable
            onClick={onSubscribeClick}
            variant="outlined"
            classes={{ root: classes.subscribeButton }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip label="Conta Demo" variant="outlined" classes={{ root: classes.demoAccountButton }} />
        </Box>
      </>
    );
  }

  if (type === 'admin') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Chip label="Admin" variant="outlined" classes={{ root: classes.demoAccountButton }} />
      </Box>
    );
  }

  if (type === 'user' && isInTrial) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Chip label="Trial" variant="outlined" classes={{ root: classes.demoAccountButton }} onClick={onTrialClick} />
      </Box>
    );
  }

  if (type === 'pioneer') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Chip label="Pioneiro" variant="outlined" classes={{ root: classes.pioneerButton }} />
      </Box>
    );
  }

  return null;
}

export default function MainLayout() {
  const classes = useStyles();

  const { isLoggedIn, setLoggedIn } = useAuthentication();
  const { navToLogin, navToRegister, navToAccount } = useNavigation();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [mobileOpen, setMobileOpen] = useState(false);
  const { data } = useGetProfileQuery();
  const showPaymentAlert = data && ['user', 'pioneer'].includes(data?.type) && !data.isSubscriptionPaid;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSubscribeClick = () => {
    setLoggedIn(false);
    navToRegister();
  };

  const handleTrialClick = () => {
    navToAccount('billing');
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navToLogin();
    }
  }, [isLoggedIn, navToLogin]);

  return (
    <AbilityProvider>
      <AppBar position="sticky" color="secondary" className={classes.appBar}>
        {showPaymentAlert && <PaymentAlert />}
        <Toolbar>
          <IconButton aria-label="open drawer" onClick={handleDrawerToggle} className={classes.menuButton} edge="start">
            <MenuIcon />
          </IconButton>

          <Routes>
            {privateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <Typography variant="subtitle2" component="h1" color="textPrimary" className={classes.title}>
                    {route.title}
                  </Typography>
                }
              />
            ))}
          </Routes>
          {data && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <WarningChip
                type={data?.type}
                isInTrial={data?.isInTrial}
                onSubscribeClick={handleSubscribeClick}
                onTrialClick={handleTrialClick}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box display="flex">
        <div className={classes.drawer}>
          <Hidden lgUp implementation="js">
            <Drawer
              anchor="left"
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <nav className={classes.drawer}>
                <Sidebar />
              </nav>
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation="js">
            <Drawer anchor="left" variant="permanent" open>
              <nav className={classes.drawer}>
                <Sidebar />
              </nav>
            </Drawer>
          </Hidden>
        </div>
        <Box component="main" width="100%" paddingTop={theme.spacing(0.5)}>
          {data && (
            <Container maxWidth="xl" disableGutters={downSm}>
              <Routes>
                {privateRoutes.map((route, index) => (
                  <Route
                    key={`route-${index}`}
                    path={route.path}
                    element={
                      <PrivateRoute redirectTo="/login" isBlocked={!route.showAlways && !data.isSubscriptionPaid}>
                        {route.component}
                      </PrivateRoute>
                    }
                  />
                ))}
              </Routes>
            </Container>
          )}
          <Can I="manage" a="Experimental">
            <ChatDrawer />
          </Can>
        </Box>
      </Box>
    </AbilityProvider>
  );
}
