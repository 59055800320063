/* eslint-disable @typescript-eslint/ban-ts-comment */
import { configureStore } from '@reduxjs/toolkit';
import activityProviderReducer from './activity-provider/activity-provider.slice';
import { api } from './api';
import { assistantApi } from './assistant/assistant.api';
import { authApi } from './authentication/authentication.api';
import authenticationReducer from './authentication/authentication.slice';
import { calendarApi } from './calendar/calendar.api';
import calendarReducer from './calendar/calendar.slice';
import confirmationDialogReducer from './confirmation-dialog/confirmation-dialog.slice';
import dashboardSlice from './dashboard/dashboard.slice';
import { entitiesApi } from './entities/entities.api';
import { expensesApi } from './expenses/expenses.api';
import expensesReducer from './expenses/expenses.slice';
import { integrationsApi } from './integrations/integrations.api';
import { invoicesApi } from './invoices/invoices.api';
import invoicesReducer from './invoices/invoices.slice';
import notificationsReducer from './notifications/notifications.slice';
import { partnersApi } from './partners/partners.api';
import { paymentInvoicesApi } from './payment-invoices/payment-invoices.api';
import paymentInvoicesReducer from './payment-invoices/payment-invoices.slice';
import { paymentMethodsApi } from './payment-methods/payment-methods.api';
import paymentMethodsReducer from './payment-methods/payment-methods.slice';
import { plansApi } from './plans/plans.api';
import plansReducer from './plans/plans.slice';
import portalLoginDialogReducer from './portal-login-dialog/portal-login-dialog.slice';
import { subscriptionsApi } from './subscriptions/subscriptions.api';
import synchronizationDialogReducer from './synchronization-dialog/synchronization-dialog.slice';
import { synchronizationApi } from './synchronization/synchronization.api';
import { taxesSettingsApi } from './taxes-settings/taxes-settings.api';
import { taxesApi } from './taxes/taxes.api';
import { userDetailsApi } from './user-details/user-details.api';

// @ts-ignore
const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    // @ts-ignore
    [userDetailsApi.reducerPath]: userDetailsApi.reducer,
    // @ts-ignore
    [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
    // @ts-ignore
    [plansApi.reducerPath]: plansApi.reducer,
    // @ts-ignore
    [calendarApi.reducerPath]: calendarApi.reducer,
    // @ts-ignore
    [expensesApi.reducerPath]: expensesApi.reducer,
    // @ts-ignore
    [entitiesApi.reducerPath]: entitiesApi.reducer,
    // @ts-ignore
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    // @ts-ignore
    [taxesApi.reducerPath]: taxesApi.reducer,
    // @ts-ignore
    [integrationsApi.reducerPath]: integrationsApi.reducer,
    // @ts-ignore
    [paymentInvoicesApi.reducerPath]: paymentInvoicesApi.reducer,
    // @ts-ignore
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    // @ts-ignore
    [partnersApi.reducerPath]: partnersApi.reducer,
    // @ts-ignore
    [taxesSettingsApi.reducerPath]: taxesSettingsApi.reducer,
    // @ts-ignore
    [synchronizationApi.reducerPath]: synchronizationApi.reducer,
    // @ts-ignore
    [assistantApi.reducerPath]: assistantApi.reducer,
    authentication: authenticationReducer,
    invoices: invoicesReducer,
    activityProvider: activityProviderReducer,
    expenses: expensesReducer,
    dashboard: dashboardSlice,
    confirmationDialog: confirmationDialogReducer,
    notifications: notificationsReducer,
    paymentMethods: paymentMethodsReducer,
    calendar: calendarReducer,
    paymentInvoices: paymentInvoicesReducer,
    portalLoginDialog: portalLoginDialogReducer,
    plans: plansReducer,
    synchronizationDialog: synchronizationDialogReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
