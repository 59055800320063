import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AssistantIcon from '@material-ui/icons/Assistant';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { useState } from 'react';
import ChatWindow from '../../components/chat/chat-window';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
    hidden: {
      display: 'none',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    drawer: {
      height: '100%',
      overflow: 'hidden',
    },
    drawerPaper: {
      height: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

export default function ChatDrawer() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerCloseClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Tooltip title="Europa, a sua assistente virtual">
        <Fab
          color="primary"
          size="large"
          className={clsx([classes.fab, { [classes.hidden]: open }])}
          onClick={handleDrawerCloseClick}
        >
          <AssistantIcon />
        </Fab>
      </Tooltip>
      <Drawer
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
        variant="persistent"
        open={open}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <div className={classes.drawerHeader}>
            <IconButton color="primary" onClick={handleDrawerCloseClick}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <Box flexGrow={1} height="calc(100% - 65px)">
            <ChatWindow />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
