import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup
  .object({
    content: yup.string().required(),
  })
  .required();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      margin: 4,
    },
  }),
);

interface ChartForm {
  content: string;
}

interface ChatInputProps {
  isLoading: boolean;
  onSubmit(message: ChartForm): void;
}

export default function ChatInput(props: ChatInputProps) {
  const { onSubmit, isLoading } = props;
  const classes = useStyles();

  const methods = useForm<ChartForm>({ resolver: yupResolver(schema), defaultValues: { content: '' } });

  useEffect(() => {
    // For some reason react hook form does not reset to defaults by.. well.. default
    methods.reset({ content: '' });
  }, [methods.formState.isSubmitSuccessful]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      methods.handleSubmit(onSubmit)();
    }
  };

  return (
    <FormProvider {...methods}>
      <Paper component="form" className={classes.root} onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="row" width="100%">
          <Controller
            name="content"
            control={methods.control}
            disabled={isLoading}
            render={({ field }) => (
              <InputBase
                {...field}
                className={classes.input}
                placeholder="Pergunta à Europa"
                fullWidth
                multiline
                onKeyDown={handleKeyDown}
              />
            )}
          />
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <IconButton
            type="submit"
            color="primary"
            className={classes.iconButton}
            disabled={!methods.formState.isValid || isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : <ArrowUpwardIcon />}
          </IconButton>
        </Box>
      </Paper>
    </FormProvider>
  );
}
